/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.btn.active {
  background-color: #ffffff !important;
}

.image {
  width: 150px;
  padding-left: 40px;
}
.content-right{
  padding-right: 40px;
}
.nav-text {
  font-size: 15px;
  letter-spacing: 1px;
}

.heading1 {
  border-bottom: red;
  border-width: 12px;
}
.home {
  background-color: #2d324d;
  background-image: url(./image/img-with-flags.png);
  background-repeat: no-repeat;
  background-size: 50% 100% !important;
  overflow: hidden;
  min-height: 100vh;
  background-position: center left;
}
.bg-image {
  background-image: url(./image/second-bg.jpg);
  min-height: calc(100vh);
  background-size: cover;
}
input.form-control {
  border-radius: 30px;
  padding: 15px;
}
.btn-outline-light {
  position: absolute;
  top: 2px;
  height: 53px;
  right: 0;
  border-radius: 0 30px 30px 0px !important;
}
.search-info {
  background-color: #fff;
  border-radius: 30px;
  color: #000;
  display: block;
  padding: 15px;
}
.bg {
  background-color: #2d324de3;
  min-height: calc(100vh);
  padding: 40px 20px;
}

.error {
  background-color: #2d324d;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 580px;
}
.navlink-text {
  letter-spacing: 2px;
}
.crose-sign {
  display: inline-block;
  padding: 0 18px;
  font-weight: bolder;
}
.err-msg {
  letter-spacing: 3px;
}
.heading-font {
  letter-spacing: 1px;
}
.search-font {
  letter-spacing: 2px;  
}
.heading {
  letter-spacing: 2px;
  max-width: 300px;
}
.err-msg{
  margin-top: 8px;
  color: red;
}
/* @media only screen and (max-width:1400px){
  .home{
    background-size: 50% 100% !important;
  }
} */
@media only screen and (max-width: 992px) {
  .bgImage {
    width: inherit !important;
    height: 375px !important;
  }
  .home {
    padding: 0 20px;
    background-image: linear-gradient(180deg, #2d324dd6, #2d324dd6),
      url(./image/second-bg.jpg);
    background-size: cover !important;
    background-position: center;
  }
  .error {
    top: 50%;
    width: 400px;
  }
}
@media only screen and (max-width: 425px) {
  .image {
    width: 80px;
    padding-left: 0;
  }
  .content-right{
    padding-right: 0;
  }
  .nav-text {
    font-size: 13px;
  }
  .error {
    top: 58%;
    width: 300px;
  }
}
@media only screen and (max-width: 575px) {
  .heading-font {
    font-size: 13px;
  }
}
